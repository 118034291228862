import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {PaymentRequestErrorCode, PaymentRequestStoreProps} from '../../../types/paymentRequestApp.types';
import {PaymentRequestService} from '../../services/paymentRequest/PaymentRequestService';
import {PaymentRequestBIService} from '../../services/paymentRequest/PaymentRequestBIService';
import {
  PolicyButtonLocation,
  PolicyType,
} from '../../../common/components/PolicyButtonWithDialog/PolicyButtonWithDialog';

export type PaymentRequestStoreConfig = {
  flowAPI: ControllerFlowAPI;
  siteStore: SiteStore;
  paymentRequestService: PaymentRequestService;
  updateComponent: () => void;
  paymentRequestBIService: PaymentRequestBIService;
};

export class PaymentRequestStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  private readonly paymentRequestService: PaymentRequestService;
  private readonly updateComponent: () => void;
  private readonly paymentRequestBIService: PaymentRequestBIService;

  constructor({
    flowAPI,
    siteStore,
    paymentRequestService,
    updateComponent,
    paymentRequestBIService,
  }: PaymentRequestStoreConfig) {
    this.flowAPI = flowAPI;
    this.siteStore = siteStore;
    this.paymentRequestService = paymentRequestService;
    this.updateComponent = updateComponent;
    this.paymentRequestBIService = paymentRequestBIService;
  }

  private readonly sendPaymentRequestPageLoadBIEvent = (): void => {
    this.paymentRequestBIService.checkoutPayingForExistingOrderCheckoutPageLoadedSrc130Evid40({
      paymentRequest: this.paymentRequestService.paymentRequest,
    });
  };

  private readonly sendClickOnPayNowButtonBIEvent = (activePaymentId?: string): void => {
    this.paymentRequestBIService.checkoutPayingForExistingOrderClickOnPayNowSrc130Evid41({
      paymentRequest: this.paymentRequestService.paymentRequest,
      activePaymentId,
    });
  };

  private readonly sendClickOnSummaryMobileViewSectionBIEvent = (action: string): void => {
    this.paymentRequestBIService.checkoutPayingForExistingOrderShowHideOverviewSectionMobileViewSrc130Evid42({
      paymentRequest: this.paymentRequestService.paymentRequest,
      action,
    });
  };

  /* istanbul ignore next */
  public setPaymentRequestError(errorCode: PaymentRequestErrorCode) {
    this.paymentRequestService.setPaymentRequestError(errorCode);
  }

  private readonly sendPaymentRequestThankYouPageLoadBIEvent = (): void => {
    this.paymentRequestBIService.checkoutPayingForExistingOrderThankYouPageLoadedSrc130Evid43({
      paymentRequest: this.paymentRequestService.paymentRequest,
    });
  };

  private readonly sendPaymentRequestExpiredPageLoadBIEvent = (): void => {
    this.paymentRequestBIService.checkoutPayingForExistingOrderPaylinkExpiredPopupSrc130Evid44({
      paymentRequest: this.paymentRequestService.paymentRequest,
    });
  };

  private readonly sendPaymentRequestErrorPageLoadBIEvent = (errorMessage: string): void => {
    this.paymentRequestBIService.checkoutPayingForExistingOrderErrorMessageSrc130Evid45({
      paymentRequest: this.paymentRequestService.paymentRequest,
      errorMessage,
    });
  };

  private readonly sendClickOnPolicyBIEvent = (linkLocation: PolicyButtonLocation, policyType: PolicyType): void => {
    this.paymentRequestBIService.checkoutPayingForExistingOrderClickOnCheckoutPoliciesSrc130Evid47({
      paymentRequest: this.paymentRequestService.paymentRequest,
      linkLocation,
      policyType,
    });
  };

  public toProps(): PaymentRequestStoreProps {
    return {
      paymentRequest: this.paymentRequestService.paymentRequest,
      sendPaymentRequestPageLoadBIEvent: this.sendPaymentRequestPageLoadBIEvent,
      sendClickOnPayNowButtonBIEvent: this.sendClickOnPayNowButtonBIEvent,
      sendClickOnSummaryMobileViewSectionBIEvent: this.sendClickOnSummaryMobileViewSectionBIEvent,
      sendPaymentRequestThankYouPageLoadBIEvent: this.sendPaymentRequestThankYouPageLoadBIEvent,
      sendPaymentRequestExpiredPageLoadBIEvent: this.sendPaymentRequestExpiredPageLoadBIEvent,
      sendPaymentRequestErrorPageLoadBIEvent: this.sendPaymentRequestErrorPageLoadBIEvent,
      sendClickOnPolicyBIEvent: this.sendClickOnPolicyBIEvent,
    };
  }
}
